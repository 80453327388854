import Vue from 'vue'

/**
 * 字串須符合 x 長度
 * @param strLen 長度字元
 */
export default function strlength (this: Vue, strLen: number) {
  return (v) => {
    if (!v) {
      return true
    } else if (v.toString().length !== strLen) {
      return this.$t('validate.strlength', { length: strLen })
    }
    return true
  }
}
